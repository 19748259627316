<template>
  <section class="row">
    <div class="col-12 col-lg-7 mb-16">
      <v-card class="p-16 py-md-32 p-lg-32">
        <div class="row">
          <div
            v-for="(checker, index) in filteredCheckers"
            :key="index"
            class="col-12 col-md-6 mb-16"
          >
            <p class="mb-4">
              <template v-if="checker.progress >= 100 && !checker.isError">
                <v-icon-svg
                  class="mr-4 svg-stroke-primary"
                  view-box="0 0 11 8"
                  title="svg-icon-checked"
                  width="11"
                  height="8"
                >
                  <svg-icon-checked />
                </v-icon-svg>
              </template>

              <template v-if="checker.isError">
                <v-icon-svg
                  class="mr-4 svg-fill-danger"
                  view-box="0 0 9 9"
                  title="svg-icon-x"
                  width=".6rem"
                  height=".6rem"
                >
                  <svg-icon-x />
                </v-icon-svg>
              </template>

              {{ checker.title || $t(`processing.check.checker.${checker.name}.title`) }}
            </p>

            <v-progress-linear
              :value="checker.progress"
              :error="checker.isError"
              height=".5rem"
            />
          </div>
        </div>
      </v-card>

      <div class="mt-32 d-none d-lg-block">
        <v-button
          :disabled="nextBtnIsDisabled"
          @click="send"
        >
          {{ $t("processing.check.message.continue") }}
        </v-button>
      </div>
    </div>

    <div class="col-12 col-lg-5 mb-16">
      <div class="c-align-vertical">
        <div class="c-align-vertical__main">
          <v-card class="p-16 py-md-32 p-lg-32 h-100">
            <div
              class="text-center"
              v-if="!isChecked && !isError"
            >
              <p>
                <b>
                  {{ $t("processing.check.message.checking") }}
                </b>
              </p>

              <v-progress-circular
                class="mt-40"
                :value="30"
                :size="48"
                indeterminate
              />
            </div>

            <div
              v-if="isChecked && !isError"
              class="text-center"
            >
              <p>
                <b>
                  {{ $t("processing.check.message.success") }}
                </b>
              </p>

              <v-icon-svg
                class="svg-fill-primary mt-8"
                view-box="0 0 50 50"
                title="svg-icon-device-checked-2"
                width="3rem"
                height="3.375rem"
              >
                <svg-icon-device-checked2 />
              </v-icon-svg>
            </div>

            <div
              v-if="isError"
            >
              <p class="text-center">
                <b>
                  {{ $t("processing.check.message.error") }}
                </b>
              </p>

              <p class="text-center my-32">
                <v-icon-svg
                  class="svg-fill-danger mr-4"
                  view-box="0 0 48 48"
                  width="3rem"
                  height="3rem"
                >
                  <svg-icon-wrench/>
                </v-icon-svg>
              </p>

              <hr>

              <div
                v-for="(error, index) in filteredErrors"
                :key="index"
                class="row my-16"
              >
                <div class="col-auto text-color-danger">*</div>
                <div class="col">
                  <p v-html="error.errorMessage || $t(`processing.check.checker.${error.name}.error`)"/>
                </div>
              </div>
            </div>

          </v-card>
        </div>

        <div class="c-align-vertical__button mt-32">
          <v-button
            :disabled="nextBtnIsDisabled"
            @click="send"
          >
            {{ $t("processing.check.button.next") }}
          </v-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Bowser from 'bowser'
import { checkAjaxApi, sendTelemetryApi } from '@/api'
import VCard from '@components/base/VCard'
import VButton from '@components/base/VButton'
import VProgressLinear from '@components/base/VProgressLinear'
import VIconSvg from '@components/base/VIconSvg'
import SvgIconChecked from '@components/icons/SvgIconChecked'
import VProgressCircular from '@components/base/VProgressCircular'
import SvgIconX from '@components/icons/SvgIconX'
import SvgIconDeviceChecked2 from '@components/icons/SvgIconDeviceChecked2'
import SvgIconWrench from '@components/icons/SvgIconWrench'

export default {
  name: 'ProcessingCheck',

  components: {
    SvgIconWrench,
    SvgIconDeviceChecked2,
    SvgIconX,
    VProgressCircular,
    SvgIconChecked,
    VIconSvg,
    VProgressLinear,
    VButton,
    VCard
  },

  inject: ['service'],

  props: {
    content: Object
  },

  data () {
    return {
      checkers: [
        {
          enabled: true,
          errorMessage: '',
          important: true, // close next button
          isChecked: false,
          isError: false,
          progress: 0,
          name: 'device',
          title: '',
          validator: deviceName => ['tablet', 'desktop'].includes(deviceName)
        },
        {
          enabled: true,
          errorMessage: '',
          important: true,
          isChecked: false,
          isError: false,
          name: 'viewport',
          progress: 0,
          title: '',
          validator: (width, height) => (width >= 600 && height >= 480)
        },
        {
          enabled: true,
          errorMessage: '',
          important: true,
          isChecked: false,
          isError: false,
          name: 'javascript',
          progress: 0,
          title: 'JavaScript'
        },
        {
          enabled: true,
          errorMessage: '',
          important: true,
          isChecked: false,
          isError: false,
          name: 'img',
          progress: 0,
          title: ''
        },
        {
          enabled: true,
          errorMessage: '',
          important: true,
          isChecked: false,
          isError: false,
          name: 'ajax',
          progress: 0,
          title: ''
        },
        {
          enabled: true,
          errorMessage: '',
          important: false,
          isChecked: false,
          isError: false,
          name: 'speed',
          progress: 0,
          title: '',
          validator: value => (value < 2.5) // 2.5 ~ 1Мбит/с
        },
        {
          enabled: true,
          errorMessage: '',
          important: false,
          isChecked: false,
          isError: false,
          name: 'browser',
          progress: 0,
          validator: {
            chrome: '>=48',
            firefox: '>=45',
            opera: '>=35',
            safari: '>=9',
            yandex: '>=13'
          },
          title: ''
        }
      ],
      browser: Bowser.getParser(window.navigator.userAgent)
    }
  },

  watch: {
    content () {
      this.fetchOptions()
    }
  },

  mounted () {
    this.fetchOptions()

    const javascriptChecker = this.getChecker('javascript')

    this.update(javascriptChecker, { progress: 100, isChecked: true })

    this.filteredCheckers.forEach(checker => {
      // javascript проверяется всегда a speed в комплекте с AJAX
      if (!['javascript', 'speed'].includes(checker.name)) {
        const capitilizedFunctionName = `check${checker.name[0].toUpperCase()}${checker.name.slice(1)}`
        this[capitilizedFunctionName]()
      }
    })
  },

  computed: {
    filteredCheckers () {
      return this.checkers.filter(checker => checker.enabled)
    },
    filteredErrors () {
      return this.filteredCheckers.filter(checker => checker.isError)
    },
    isChecked () {
      return this.filteredCheckers.every(checker => checker.isChecked)
    },
    isError () {
      return this.filteredErrors.length > 0
    },
    nextBtnIsDisabled () {
      const importantErrors = this.filteredErrors.some(checker => checker.important)
      return !(this.isChecked && !importantErrors)
    }
  },

  methods: {
    getChecker (checkerName) {
      return this.checkers.find(checker => checker.name === checkerName)
    },
    fetchOptions () {
      if (!Object.values(this.content).length) return

      const getValidation = (checkerName, options) => {
        switch (checkerName) {
          case 'device': {
            const allowedDevices = Object.entries(options).filter(([_key, value]) => value).map(([key]) => key)
            return deviceName => allowedDevices.includes(deviceName)
          }
          case 'viewport': {
            return (width, height) => (width >= options.width && height >= options.height)
          }
        }
      }

      Object.entries(this.content).forEach(([checkerName, values]) => {
        const checker = this.getChecker(checkerName)
        const { options, ...restValues } = values

        this.update(checker, restValues)

        if (options) {
          Object.assign(checker, { validator: getValidation(checkerName, options) })
        }
      })
    },
    checkDevice () {
      const deviceChecker = this.getChecker('device')

      if (!deviceChecker.validator(this.browser.getPlatformType())) {
        this.update(deviceChecker, { isError: true })
      }

      this.update(deviceChecker, { progress: 100, isChecked: true })
    },
    checkViewport () {
      const viewportChecker = this.getChecker('viewport')
      const viewport = window.visualViewport
        ? window.visualViewport
        : { width: window.innerWidth, height: window.innerHeight }

      if (!viewportChecker.validator(viewport.width, viewport.height)) {
        this.update(viewportChecker, { isError: true })
      }

      this.update(viewportChecker, { progress: 100, isChecked: true })
    },
    checkAjax () {
      const ajaxChecker = this.getChecker('ajax')
      const startUTC = +new Date()

      checkAjaxApi({
        onDownloadProgress: progressEvent => {
          const percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
          this.update(ajaxChecker, { progress: percentCompleted })
        }
      })
        .then(
          () => {
            const endUTC = +new Date()

            this.update(ajaxChecker, { progress: 100 })
            this.checkSpeed(startUTC, endUTC)
          },
          () => {
            this.update(ajaxChecker, { isError: true })
          }
        )
        .then(() => {
          this.update(ajaxChecker, { isChecked: true })
        })
    },
    checkSpeed (startUTC, endUTC) {
      const speedChecker = this.getChecker('speed')
      const duration = (endUTC - startUTC) / 1000

      this.update(speedChecker, { progress: 100, isChecked: true })

      if (!speedChecker.validator(duration)) {
        this.update(speedChecker, { isError: true })

        this
          .sendTelemetry({
            session_uuid: this.service.event,
            state: { slow_internet_speed: true }
          })
          .catch((err) => {
            // TODO добавить обработку ошибки
            console.error(err)
          })
      }
    },
    checkImg () {
      const imgChecker = this.getChecker('img')
      const self = this
      const image = new Image()
      const timer = setTimeout(() => {
        self.update(imgChecker, { progress: 100, isError: true, isChecked: true })
      }, 3000)

      image.onload = function () {
        if (this.width + this.height > 0) {
          clearTimeout(timer)
          self.update(imgChecker, { progress: 100, isError: false, isChecked: true })
        }
      }

      image.src = require('@/assets/images/nologo.png')
    },
    checkBrowser () {
      const browserChecker = this.getChecker('browser')

      if (!this.browser.satisfies(browserChecker.validator)) {
        this.update(browserChecker, { isError: true })
      }

      this.update(browserChecker, { progress: 100, isChecked: true })
    },
    sendTelemetry (data) {
      let attemptCount = 2

      return new Promise((resolve, reject) => {
        (function trySend () {
          sendTelemetryApi(data)
            .then(() => resolve())
            .catch(err => {
              if (attemptCount) {
                attemptCount--
                trySend()
              } else {
                reject(err)
              }
            })
        })()
      })
    },
    update (checker, options) {
      Object.assign(checker, options)
    },
    send () {
      this.$emit('send')
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables/index.scss";
@import "~bootstrap/scss/mixins/breakpoints";

.c-align-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  // по-другому решить не удалось
  &__button {
    @include media-breakpoint-up(lg) {
      visibility: hidden;
    }
  }
}
</style>
